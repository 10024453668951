


export const DAYSINWEEK = [
    "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"
];

export const DAYSINWEEKSHORT = [
    "mon", "tue", "wed", "thu", "fri", "sat", "sun"
];
